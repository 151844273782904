import {
  StartPostMediaUploadMutation,
  StartPostMediaUploadDocument,
  StartPostMediaUploadMutationVariables,
  CompletePostMediaUploadMutationVariables,
  CompletePostMediaUploadMutation,
  CompletePostMediaUploadDocument,
  GetDownloadUrlQueryVariables,
  GetDownloadUrlDocument,
  GetDownloadUrlQuery,
} from '../generated/graphql'
import { apolloClient } from '../apolloClient'
import { GraphQLError } from 'graphql'
import { DateTime } from 'luxon'
import axios from 'axios'
import { ApolloConsumer, gql } from '@apollo/client'

export const GRAPHQL_QUERIES = gql`
  mutation startPostMediaUpload($post_id: String!, $contentType: String!) {
    startPostMediaUpload(post_id: $post_id, contentType: $contentType) {
      uploadUri
      uploadKey
    }
  }

  mutation completePostMediaUpload($uploadKey: String) {
    completePostMediaUpload(uploadKey: $uploadKey) {
      post {
        primaryVideo {
          key
        }
        primaryPoster {
          key
        }
      }
      sourceUrl
      contentType
    }
  }

  query getDownloadUrl($collection_id: String!, $post_id: String!, $media_key: String!, $format_key: String!) {
    getCollection(collection_id: $collection_id) {
      post(post_id: $post_id) {
        mediaFile(mediafile_key: $media_key) {
          format(format_key: $format_key) {
            downloadUrl
          }
        }
      }
    }
  }
`

interface ErrorResult {
  success: false
  errors?: ReadonlyArray<GraphQLError | string>
}

interface SuccessResult<T> {
  success: true
  result: T
}

type SuccessOrErrorResult<T> = ErrorResult | SuccessResult<T>
export interface FileTransferProgressInfo {
  percentComplete: number
  totalBytes: number
  completedBytes: number
  startedTime: DateTime
}
export type ProgressReporter = (progress: FileTransferProgressInfo) => void

interface doVideoUploadResult {
  sourceUrl: string
}

interface FileUploadToolArgs {
  uploadUri: string
  method: 'PUT' | 'POST'
  body: ArrayBuffer | Blob | string
  headers: Record<string, string>
  progressReporter?: ProgressReporter
}
interface FileUploadToolResponse {
  statusText: string
  status: number
}
type FileUploadTool = (args: FileUploadToolArgs) => Promise<FileUploadToolResponse>

/* // for future use once fetch adds progress reporting
const fetchFileUploadTool: FileUploadTool = async ({ uploadUri: uploadUri, method, body, headers }) => {
  return await fetch(uploadUri, {
    method,
    body,
    headers,
  })
}
*/

const axiosFileUploadTool: FileUploadTool = async ({ uploadUri, method, body, headers, progressReporter }) => {
  const startedTime = DateTime.utc()
  const result = await axios({
    method,
    url: uploadUri,
    data: body,
    headers,
    onUploadProgress: (progressInfo: ProgressEvent) =>
      progressReporter &&
      progressReporter({
        totalBytes: progressInfo.total,
        completedBytes: progressInfo.loaded,
        startedTime,
        percentComplete: (progressInfo.loaded * 100) / progressInfo.total,
      }),
  })

  return {
    status: result.status,
    statusText: result.statusText,
  }
}

export async function doVideoUpload(
  post_id: string,
  file: File,
  progressReporter?: ProgressReporter
): Promise<SuccessOrErrorResult<doVideoUploadResult>> {
  // which file upload tool will we use? (hard-config for now)
  const fileUploadTool = axiosFileUploadTool
  // const fileUploadTool = fetchFileUploadTool

  console.log('file', file)
  console.debug('type', file.type)

  const startResult = await apolloClient.mutate<StartPostMediaUploadMutation, StartPostMediaUploadMutationVariables>({
    mutation: StartPostMediaUploadDocument,
    variables: {
      post_id,
      contentType: file.type,
    },
  })
  console.debug('startResult', startResult)
  if (startResult.errors || !startResult?.data?.startPostMediaUpload) return { success: false, errors: startResult.errors }
  const { uploadUri, uploadKey } = startResult.data.startPostMediaUpload

  if (uploadUri.indexOf('core.windows.net') > -1) {
    console.debug(`Using Azure block upload for large file support`)
    await doAzureBlobChunkUpload(uploadUri, file, progressReporter)
  } else {
    console.debug(`Starting blob PUT (single request mode)`)
    const uploadResult = await fileUploadTool({
      uploadUri,
      method: 'PUT',
      body: await file.arrayBuffer(),
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-version': '2017-11-09',
        'Content-Type': file.type,
        'x-ms-date': DateTime.utc().toHTTP(),
        // 'x-ms-date':'',
      },
      progressReporter,
    })

    if (uploadResult.status !== 201) {
      console.log(`Error attempting to perform upload: ${uploadResult.statusText}`)
      return { success: false, errors: [uploadResult.statusText] }
    }
  }

  console.debug(`Starting completePostMediaUploadMutation`)
  const completeResult = await apolloClient.mutate<CompletePostMediaUploadMutation, CompletePostMediaUploadMutationVariables>({
    mutation: CompletePostMediaUploadDocument,
    variables: {
      uploadKey,
    },
    // update cached data with uploaded data
    update(cache, { data }) {
      const cacheID = cache.identify({ id: post_id, __typename: 'Post' })
      const post = data?.completePostMediaUpload?.post
      console.log(`in update method: ${cacheID}`)
      if (post) {
        cache.modify({
          id: cacheID,
          // fields(fieldValue, details) {
          //   console.log(`fieldValue: ${fieldValue}, name: ${details.fieldName}`)
          // },
          fields: {
            primaryVideo(cachedPrimaryVideo) {
              console.log(`Invalidating cache, was ${JSON.stringify(cachedPrimaryVideo)} now ${JSON.stringify(post.primaryVideo)}`)
              return post.primaryVideo
            },
            primaryPoster(cachedPrimaryPoster) {
              console.log(
                `Invalidating cache, was ${JSON.stringify(cachedPrimaryPoster)} now ${JSON.stringify(post.primaryPoster)}`
              )
              return post.primaryPoster
            },
          },
          broadcast: true,
        })
      }
    },
  })
  if (completeResult.errors || !completeResult?.data?.completePostMediaUpload) return { success: false, errors: startResult.errors }

  const video = completeResult.data?.completePostMediaUpload

  const videoOut = {
    sourceUrl: video.sourceUrl,
  }

  if (!videoOut.sourceUrl) {
    return { success: false, errors: ['Empty data returned from complete operation'] }
  }

  console.log(`doVideoUpload complete`)
  return { success: true, result: videoOut }
}

export const doAzureBlobChunkUpload = async (uploadUri: string, file: File, progressReporter?: ProgressReporter) => {
  const startedTime = DateTime.utc()
  // bytes per upload block/chunk
  const chunkSize = 25_000_000
  let bytesUploaded = 0
  let chunkNum = 0
  const blockNames = []
  // rolls up progress across chunks
  const internalProgressReporter = (progress: FileTransferProgressInfo) => {
    if (progressReporter) {
      const completedBytes = progress.completedBytes + bytesUploaded
      progressReporter({ completedBytes, startedTime, totalBytes: file.size, percentComplete: (100 * completedBytes) / file.size })
    }
  }

  // upload each block
  while (bytesUploaded < file.size) {
    const blob = file.slice(bytesUploaded, bytesUploaded + chunkSize)
    const blockNameEncoded = btoa(String(`0000${chunkNum}`.slice(-4)))
    blockNames.push(blockNameEncoded)
    const blockUploadUri = uploadUri + `&comp=block&blockid=${encodeURIComponent(blockNameEncoded)}`
    console.log(`Uploading blockl #${chunkNum} name ${blockNameEncoded} size ${blob.size} to ${blockUploadUri}`)
    const uploadResult = await axiosFileUploadTool({
      uploadUri: blockUploadUri,
      method: 'PUT',
      body: blob,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-version': '2017-11-09',
        'Content-Type': file.type,
        'x-ms-date': DateTime.utc().toHTTP(),
        // 'x-ms-date':'',
      },
      progressReporter: internalProgressReporter,
    })
    if (uploadResult.status !== 201) {
      console.log(`Error attempting to perform upload: ${uploadResult.statusText}`)
      return { success: false, errors: [uploadResult.statusText] }
    }
    console.log(`Block ${chunkNum} complete`)
    chunkNum++
    bytesUploaded += blob.size
  }

  // finalize the upload by sending the block list
  const blockPayload = `<?xml version="1.0" encoding="utf-8"?><BlockList>${blockNames
    .map((bn) => `<Latest>${bn}</Latest>`)
    .join('')}</BlockList>`
  const blockListUri = uploadUri + '&comp=blocklist'

  console.log(`Sending block list to complete upload:\n${blockPayload}`)
  const blockListResult = await axiosFileUploadTool({
    uploadUri: blockListUri,
    method: 'PUT',
    body: blockPayload,
    headers: {
      'x-ms-version': '2017-11-09',
      'Content-Type': 'text/plain',
      'x-ms-date': DateTime.utc().toHTTP(),
      'x-ms-blob-content-type': file.type,
    },
  })
  if (blockListResult.status !== 201) {
    console.log(`Error attempting to perform upload: ${blockListResult.statusText}`)
    return { success: false, errors: [blockListResult.statusText] }
  }
}

export const startPostMediaDownload = async (collection_id: string, post_id: string, media_key: string, format_key: string) => {
  const result = await apolloClient.query<GetDownloadUrlQuery, GetDownloadUrlQueryVariables>({
    query: GetDownloadUrlDocument,
    variables: {
      collection_id,
      post_id,
      media_key,
      format_key,
    },
  })

  const downloadUrl = result.data && result.data.getCollection?.post?.mediaFile?.format?.downloadUrl
  if (downloadUrl) {
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.href = downloadUrl
    link.click()
    document.body.removeChild(link)
  }
}
