import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import videojs, { VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.css'
// import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { setCurrentTime, setVideoInfo } from './VideoPlayerSlice'
import { useVideoPlayerContext } from './videoPlayerContext'
import debugModule from 'debug'
const debug = debugModule('videohub:VideoPlayer')

export type VideoSource = videojs.Tech.SourceObject[]

export interface OnTimeUpdateResult {
  currentTime: number
}

export const secondsToMs = (seconds: number) => Math.floor(seconds * 1000)

export interface usePlayerProps {
  src: VideoSource
  poster?: string
  controls?: boolean
  autoplay?: boolean
  defaultPlaybackRate?: number
  playbackRates?: number[]
  onReady?: () => void
  onTimeUpdate?: (result: OnTimeUpdateResult) => void
  setCurrentTime?: (currentTime: number) => void
}
// eslint-disable-next-line import/prefer-default-export
const usePlayer = ({
  src,
  controls,
  autoplay,
  defaultPlaybackRate,
  playbackRates,
  poster,
  onReady,
  onTimeUpdate,
}: usePlayerProps) => {
  const options = {
    fill: true,
    fluid: false,
    controls: true,
    autoplay: false,
    preload: 'auto',
    html5: {
      hls: {
        enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
      },
    },
  }
  const videoRef = useRef(null)
  const videoContext = useVideoPlayerContext()
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null)
  // const dispatch = useAppDispatch()

  const getOptions = () => ({
    ...options,
    controls,
    autoplay,
    poster,
    sources: src,
    playbackRates,
  })

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current ?? '', getOptions())
    if (defaultPlaybackRate !== undefined) vjsPlayer.defaultPlaybackRate(defaultPlaybackRate)

    setPlayer(vjsPlayer)
    videoContext.setPlayer?.(vjsPlayer)
    // dispatch(setPlayerRedux(vjsPlayer))

    return () => {
      if (player !== null) {
        player.dispose()
      }
    }
  }, [player, src, controls, autoplay, playbackRates, defaultPlaybackRate, poster, JSON.stringify(options)])

  useEffect(() => {
    if (player !== null) {
      player.src(src)
    }
  }, [src, player])

  // event binding
  useEffect(() => {
    if (onReady && player) player.ready(onReady)
    // if (onTimeUpdate && player) player.on('timeupdate', () => onTimeUpdate({ currentTime: player.currentTime() }))
    if (player) {
      player.on('timeupdate', () => {
        const currentTime = player.currentTime()
        debug('event timeupdate: currentTime %d', currentTime)
        setCurrentTime(currentTime)
        videoContext.setCurrentTime(secondsToMs(currentTime))
        // dispatch(setCurrentTime(secondsToMs(currentTime)))
        if (onTimeUpdate) onTimeUpdate({ currentTime })
      })
      player?.on('durationchange', () => {
        const duration = player.duration()
        debug('event durationChange: duration: %d', duration)
        videoContext.setVideoInfo({ duration: secondsToMs(duration) })
        // dispatch(setVideoInfo({ duration: duration && secondsToMs(duration) }))
        setVideoInfo({ duration })
      })
    }
  }, [onReady, onTimeUpdate, player])

  return videoRef
}

export const VideoPlayer = (props: usePlayerProps) => {
  const playerRef = usePlayer(props)

  return (
    <div data-vjs-player className="">
      <video ref={playerRef} className="video-js" />
    </div>
  )
}

VideoPlayer.propTypes = {
  src: PropTypes.array.isRequired,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool,
}

VideoPlayer.defaultProps = {
  controls: true,
  autoplay: false,
}

export default VideoPlayer
